<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      elevation="4"
      max-width="500px"
      width="100%"
      class="d-flex flex-column"
    >
      <div class="pa-5 greyish--text text-center text-h5" v-if="appId == 1">
        <v-avatar> <v-img src="@/assets/ethnosimo.png"></v-img></v-avatar>
        Ακαδημία Εμπορικού Ναυτικού
      </div>
      <div class="pa-5 greyish--text text-center text-h5" v-else>
        <v-avatar> <v-img src="@/assets/aggyres.png"></v-img></v-avatar>
        Δημόσια Σχολή Εμπορικού Ναυτικού
      </div>
      <div v-if="!loginClicked" class="d-flex flex-column align-center pa-5">
        <v-btn
          dense
          large
          color="primary mb-4"
          width="300px"
          @click="loginWithTaxis"
          >Σύνδεση με taxisnet</v-btn
        >
        <v-btn
          text
          @click="loginClicked = true"
          color="primary "
          >Σύνδεση χρηστών πιστοποιημένων από Υ.ΝΑ.Ν.Π./Δ.ΕΚ.Ν.</v-btn
        >
      </div>

      <v-form
        v-if="loginClicked"
        @submit.prevent="onLogin"
        class="d-flex flex-column pa-5 pt-0"
      >
        <v-text-field
          dense
          outlined
          v-model="username"
          label="Όνομα χρήστη"
          type="text"
          prepend-inner-icon="mdi-account"
          class="mb-4"
        ></v-text-field>
        <v-text-field
          dense
          outlined
          v-model="password"
          label="Κωδικός πρόσβασης"
          type="password"
          prepend-inner-icon="mdi-lock"
          class="mb-4"
        ></v-text-field>
        <div class="mb-4 d-flex">
          <v-btn depressed @click="onReturn" color="error " width="50%"
            >Επιστροφή</v-btn
          >
          <v-btn
            dense
            :disabled="username == '' || password == '' ? true : false"
            :loading="loader"
            type="submit"
            color="primary "
            class="ml-2"
            width="50%"
            >Σύνδεση</v-btn
          >
        </div>
      </v-form>
    </v-card>
    <v-snackbar v-model="snackbar.visible" color="error">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          dense
          text
          icon
          x-small
          v-bind="attrs"
          @click="snackbar.visible = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
export default {
  created() {
    console.log("type ", this.appId);
  },
  data() {
    return {
      loginClicked: false,
      username: "",
      password: "",
      loader: false,
      snackbar: {
        visible: false,
        text: "",
      },
      taxisLink: `${process.env.VUE_APP_BASE_URL}/oauth2/authorization/taxi`,
    };
  },
  computed: {
    ...mapState({
      appId: (state) => state.auth.appId,
      selectedRole: (state) => state.auth.selectedRole,
      userInfo: (state) => state.auth,
    }),
  },
  methods: {
    ...mapActions(["login"]),

    async onLogin() {
      try {
        this.loader = true;
        await this.login({
          username: this.username,
          password: this.password,
          type: this.appId,
        });
        this.loader = false;
        let page = this.userInfo.userInfo.roles.filter((m) => {
          if (m.id == this.selectedRole) {
            return m;
          }
        });
        console.log(page);
        this.$router.push(page[0].defaultPage);
      } catch (e) {
        console.log(e);
        this.loader = false;
        this.snackbar.visible = true;
        this.snackbar.text = "Τα στοιχεία σύνδεσης είναι εσφαλμένα";
      }
    },

    onReturn() {
      this.username = "";
      this.password = "";
      this.loginClicked = false;
    },
    async loginWithTaxis() {
      console.log("loginWithTaxis");
      window.location.href = `${process.env.VUE_APP_BASE_URL}/oauth2/authorization/taxis`;
    },
  },
};
</script>

<style scoped lang="scss">
// button.taxisnet-button.v-btn.v-btn--plain.v-btn--text.theme--light.v-size--small.primary--text {
//   text-transform: none !important;
// }

.text {
  width: 100% !important;
}
</style>